import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { useDispatch } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import '../css/style.css';
import Caterory from "../component/catergory";
import Matches from "../component/matches";

const seoMetaMap = {
    all: {
        title: 'Free Cricket Live Streaming on PBC88 Bangla',
        description: 'Watch free live cricket streaming on PBC88 Bangla and enjoy uninterrupted coverage of your favorite matches in high quality. Stay updated with live scores, expert commentary, and exclusive match highlights—all for free on PBC88 Bangla!',
    },
    cricket: {
        title: "Today's cricket match live score",
        description: "Get real-time updates on today’s cricket match live scores along with the latest trends from the cricket exchange market. Track ball-by-ball commentary, player statistics, and dynamic odds from leading cricket trading platforms. Stay ahead with live match data, market fluctuations, and expert insights—all in one place!",
    },
    football: {
        title: "Watch Football Live with La Liga for Free",
        description: "Football live with La Liga for free brings you real-time match action, player stats, and expert insights. Enhance your experience by exploring sports betting opportunities with the latest odds, live predictions, and in-depth analysis. Stay ahead in the game with valuable insights for informed sports lottery decisions!",
    },
    basketball: {
        title: "NBA live & ESPN scores",
        description: "Free NBA schedule live streaming keeps you updated with real-time game action, player stats, and expert analysis. Follow the latest NBA odds, betting trends, and market fluctuations to make informed decisions. Get live updates on point spreads, over/under totals, and moneyline odds while enjoying uninterrupted basketball action!",
    },
    tennis: {
        title: "Roland Garros' exciting tennis live broadcast",
        description: "Watch Roland Garros' exciting tennis live broadcast and stay updated with real-time match action, player performances, and expert commentary. Explore live tennis betting opportunities with the latest odds, set predictions, and in-depth analysis. Track market trends, in-play wagers, and dynamic betting options to enhance your tennis experience!",
    },
    esports: {
        title: "LOL esports games",
        description: "LOL esports games bring intense battles, strategic gameplay, and high-level competition from the world's top teams. Stay updated with live streaming, real-time match analysis, and expert commentary. Explore dynamic betting odds, match predictions, and in-depth market insights to elevate your esports experience!",
    }
};


const Home = () => {
    const dispatch = useDispatch();

 

    const { category = 'all' } = useParams();

    const categoryKey = category.toLowerCase();
    const meta = seoMetaMap[categoryKey] || seoMetaMap.all;

    const metaURL = `https://pbc88-live.app/${category}/1`;
    const metaImage = 'https://pbc88-live.app/img/PBC88 LIVE.jpg';
    

    useEffect(() => {
    
        
    }, [dispatch]);

    return (
        <>
            <Helmet>
                <title>{meta.title}</title>
                <meta name="description" content={meta.description} />
                <meta property="og:title" content={meta.title} />
                <meta property="og:description" content={meta.description} />
                <meta property="og:url" content={metaURL} />
                <meta property="og:image" content={metaImage} />
            </Helmet>

            <div className="matches">
                <Caterory />
                <Matches />
            </div>
        </>
    );
};

export default Home;
