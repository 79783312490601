import React, { useCallback, useEffect, useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux'; // ✅ Import this
import { Helmet } from 'react-helmet-async';
import { getLiveStreamByMatchid } from '../service/getLiveStreamByMatchid';
import eyewatcher from "../assets/images/eye-watcher.svg";
import getSessionId from '../utils/getSessionId';
import joinMatch from '../actions/joinMatch';
import leaveMatch from '../actions/leaveMatch';
import fetchViewerCount from '../actions/fetchViewerCount';

const LiveStream = () => {
    const { matchId } = useParams();
    const location = useLocation();

    // ✅ 1. Get match data from location or Redux
    const matchFromLocation = location.state?.match;
    const allMatches = useSelector((state) => state.data.data); // <-- Update if you use a custom selector
    const matchFromStore = allMatches.find(m => String(m.MatchID) === matchId); // ensure string comparison

    const match = matchFromLocation || matchFromStore;

    const [liveStreamLink, setLiveStreamLink] = useState(null);
    const [activeUsers, setActiveUsers] = useState(1);

    const apiurl = process.env.REACT_APP_APIURL;
    const sessionId = getSessionId();

    sessionStorage.setItem("matchId", matchId);

    // Fetch live stream link
    useEffect(() => {
        const fetchLiveStream = async () => {
            try {
                const link = await getLiveStreamByMatchid(matchId);
                setLiveStreamLink(link);
            } catch (error) {
                console.error('Error fetching live stream link:', error);
            }
        };
        fetchLiveStream();
    }, [matchId]);

    const handleJoinMatch = useCallback(() => joinMatch(apiurl, matchId, sessionId), [apiurl, matchId, sessionId]);
    const handleLeaveMatch = useCallback(() => leaveMatch(apiurl, matchId, sessionId), [apiurl, matchId, sessionId]);

    useEffect(() => {
        const updateViewerCount = () => fetchViewerCount(apiurl, matchId, setActiveUsers);
        const intervalId = setInterval(updateViewerCount, 5000);
        handleJoinMatch().then(updateViewerCount);
        return () => clearInterval(intervalId);
    }, [handleJoinMatch, apiurl, matchId]);

    useEffect(() => {
        const handleBeforeUnload = (event) => {
            handleLeaveMatch();
            event.returnValue = '';
        };

        const handleVisibilityChange = () => {
            if (document.visibilityState === 'hidden') {
                handleLeaveMatch();
            } else if (document.visibilityState === 'visible') {
                handleJoinMatch();
            }
        };

        window.addEventListener('beforeunload', handleBeforeUnload);
        document.addEventListener('visibilitychange', handleVisibilityChange);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
            document.removeEventListener('visibilitychange', handleVisibilityChange);
        };
    }, [handleLeaveMatch, handleJoinMatch]);

    const toggleIframeFullScreen = () => {
        const iframe = document.getElementById("iframe");
        const fullscreenButton = document.querySelector(".iconfullscreen");
        const body = document.body;

        if (!iframe) return;

        if (iframe.requestFullscreen || iframe.mozRequestFullScreen || iframe.webkitRequestFullscreen || iframe.msRequestFullscreen) {
            if (!document.fullscreenElement) {
                if (iframe.requestFullscreen) iframe.requestFullscreen();
                else if (iframe.mozRequestFullScreen) iframe.mozRequestFullScreen();
                else if (iframe.webkitRequestFullscreen) iframe.webkitRequestFullscreen();
                else if (iframe.msRequestFullscreen) iframe.msRequestFullscreen();
                fullscreenButton.classList.add('in-fullscreen');
            } else {
                document.exitFullscreen?.();
                fullscreenButton.classList.remove('in-fullscreen');
            }
        } else {
            const isFullScreen = iframe.classList.contains('iframe-fullscreen');
            iframe.classList.toggle('iframe-fullscreen', !isFullScreen);
            body.classList.toggle('iframe-fullscreen-mode', !isFullScreen);
            fullscreenButton.classList.toggle('in-fullscreen', !isFullScreen);
        }
    };

    window.addEventListener("orientationchange", () => {
        const iframe = document.getElementById("playframe");
        if (iframe && document.fullscreenElement) {
            iframe.style.width = window.innerWidth + "px";
            iframe.style.height = window.innerHeight + "px";
        }
    });

    // Dynamic title/meta based on match
    const pageTitle = match
        ? `${match.Home} VS ${match.Away} | ${match.TimeStart} | ${match.League}`
        : `Live Stream - Match ${matchId}`;

    const metaDescription = match
        ? `Watch the live stream for ${match.Home} vs ${match.Away} at ${match.TimeStart} in ${match.League}`
        : `Join us for the live stream of match ${matchId}.`;

    return (
        <>
            <Helmet>
                <title>{pageTitle}</title>
                <meta name="description" content={metaDescription} />
                <meta property="og:title" content={pageTitle} />
                <meta property="og:description" content={metaDescription} />
                <meta property="og:url" content={`https://pbc88-live.app/live-stream/${matchId}`} />
            </Helmet>

            <div className='container'>
                <a href='/' className='btn-back'>Back</a>

                <div className='video-wrapper'>
                    <div className='video-container' id='iframe'>
                        <div className='liveVisit'>
                            <small>LIVE</small>
                            <span><img src={eyewatcher} alt='watchers' /> {activeUsers}</span>
                        </div>

                        {liveStreamLink ? (
                            <iframe
                                src={liveStreamLink}
                                style={{
                                    display: 'block',
                                    padding: '0px 0',
                                    border: '1px solid #000',
                                    backgroundColor: '#000',
                                    width: '100%'
                                }}
                                width="720"
                                height="480"
                                title='live stream'
                                id="playframe"
                                allowFullScreen
                                allow='fullscreen'
                            ></iframe>
                        ) : (
                            <div className="box-spinner"></div>
                        )}

                        <div onClick={toggleIframeFullScreen} className='fullscreenIcon'>
                            <i className='iconfullscreen'></i>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default LiveStream;
