import React, { useEffect, useCallback } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { HelmetProvider } from 'react-helmet-async';
import Home from './pages/home';
import LiveStream from './pages/livestream';
import MatchStats from './pages/matchStats';
import leaveMatch from './actions/leaveMatch';
import { removeSessionData } from '../src/utils/session';
import { setData, setLoading, setError } from './storage/store';
import { getMatches } from './service/getMatches';

function NavigationHandler({ apiurl, matchId, handledLeave }) {
  const location = useLocation();

  useEffect(() => {
    const handleNavigationChange = () => {
      setTimeout(() => {
        if (location.pathname === '/') {
          console.log('You are on the home page!');
          removeSessionData('matchId');
          handledLeave();
        }
      }, 1000);
    };

    handleNavigationChange();
    window.addEventListener('popstate', handleNavigationChange);
    window.addEventListener('touchstart', handleNavigationChange);

    return () => {
      window.removeEventListener('popstate', handleNavigationChange);
      window.removeEventListener('touchstart', handleNavigationChange);
    };
  }, [location, handledLeave]);

  return null;
}

function App() {
  const dispatch = useDispatch();
  const matchId = sessionStorage.getItem('matchId') || null;
  const apiurl = process.env.REACT_APP_APIURL;

  const handledLeave = useCallback(() => {
    if (matchId) {
      leaveMatch(apiurl, matchId, sessionStorage.getItem('sessionId'))
        .then(() => sessionStorage.removeItem('matchId'))
        .catch((error) => console.error('Error leaving match:', error));
    }
  }, [apiurl, matchId]);

  // ✅ Centralized fetch on app load
  useEffect(() => {
    const fetchData = async () => {
      dispatch(setLoading(true));
      try {
        const response = await getMatches();
        if (!response || response.length === 0) throw new Error('Empty match data');
        dispatch(setData(response));
      } catch (error) {
        dispatch(setError(error.toString()));
      }
    };

    fetchData();
  }, [dispatch]);

  return (
    <HelmetProvider>
      <Router>
        <NavigationHandler apiurl={apiurl} matchId={matchId} handledLeave={handledLeave} />

        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/:category/:page' element={<Home />} />
          <Route path='/livestream/:matchId' element={<LiveStream />} />
          <Route path='/match-stats/' element={<MatchStats />} />
        </Routes>
      </Router>
    </HelmetProvider>
  );
}

export default App;
